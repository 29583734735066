<template>
  <member-setting-container>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          xl="6"
          :class="{ 'd-flex justify-content-end': isMedium }"
        >
          <passport-card 
            v-if="loggedMember" 
            :logged-member="loggedMember" 
            :is-modal="false" 
            :printing="printing" 
            :copied="copied" 
            :class="{ 'mr-3': !isSmall }"
          />
          <div v-else class="d-flex flex-column align-items-center justify-content-center mt-5">
            <b-spinner variant="primary" class="mb-3"/>
            <h5 class="text-primary">
              {{ $t('backoffice.passport-qr.creating-passport') }}
            </h5>
          </div>
        </b-col>

        <b-col>
          <div v-if="loggedMember" class="my-2" :class="{ 'text-center': !isMedium }">
            <b-button class="font-weight-bold mt-1" :class="{ 'mr-1': !isSmall }" :variant="'primary'" @click="handlePrintCode()">
              <span class="d-flex pb-25">
                <feather-icon icon="PrinterIcon" size="18" class="d-block mx-auto mr-1" />
                <span class="pl-50 pt-25">
                  {{ $t('settings.print-code') }}
                </span>
              </span>
            </b-button>
            <b-button class="font-weight-bold mt-1" :variant="'primary'" @click="handleClipboardCopied()">
              <span class="d-flex pb-25">
                <feather-icon icon="CopyIcon" size="18" class="d-block mx-auto mr-1" />
                <span class="pl-50 pt-25">
                  {{ $t('share.clipboard-title') }}
                </span>
              </span>
            </b-button>
          </div>
          <b-alert :show="copied" variant="success" class="text-center my-2 w-50" :class="{ 'd-block mx-auto': !isMedium }">
            {{ $t('share.copied-message') }}
          </b-alert>
          <h4 v-if="loggedMember && loggedMember.key" class="my-2">
            <b-icon icon="person-bounding-box" scale="1.2" variant="success" class="mr-75"></b-icon>
            {{ $t('backoffice.num-passport') }}: {{ hexToDecimal(loggedMember) }}
          </h4>
          <div class="mt-1 border-top pt-2">
            <h5 class="mb-2">
              {{ $t('backoffice.passport-qr.method') }}
            </h5>

            <div class="mb-2">
              <h6>
                <b-icon icon="clipboard-check" scale="1.2" variant="success" class="mr-50"></b-icon>
                {{ $t('backoffice.passport-qr.verify-title') }}:
              </h6> 
              <p class="mt-75">
                {{ $t('backoffice.passport-qr.verify-text') }}
              </p>
            </div>
            <div class="mt-2">
              <h6 class="mb-50 mt-2">        
                <b-icon icon="upc-scan" scale="1.2" variant="success" class="mr-50"></b-icon>
                {{ $t('backoffice.passport-qr.visit-card-title') }}
              </h6> 
              <p class="mt-75">
                {{ $t('backoffice.passport-qr.visit-card-text') }}
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </member-setting-container>
</template>

<script>
import ErrorPlaceholder from '@/assets/images/placeholders/light/acceso_restringido.svg';
import MemberSettingContainer from '@/views/myself/components/MemberSettingContainer.vue';
import Service from "@/config/service-identifiers";
import PassportCard from "@core/components/members/Passport.vue";
import { setTimeout } from 'timers';

export default {
  name: 'Passport',
  components: {
    MemberSettingContainer,
    PassportCard,
  },
  data() {
    return {
      isStaff: false,
      isModalOpened: false,
      isCopied: false,
      isModalAttendanceOpened: false,
      isSmall: false,
      isMedium: false,
      loggedMember: null,
      printing: false,
      copied: false,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    member() {
      return this.$store.getters.loggedMember;
    },
    mainCommunity() {
      return this.$store.getters.mainCollective;
    },
    imgUrl() {
      return ErrorPlaceholder;
    },
    // qrUrl() {
    //   const url = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
    //     '{subdomain}',
    //     this.currentCollective.subdomain || 'app',
    //   ).replace('{slug}', this.currentCollective.slug);
    //   return `${url}/backoffice/members/${this.loggedMember.username}`;
    // },
    // link() {
    //   console.log('this.qrUrl', this.qrUrl);

    //   const qrCHL = encodeURIComponent(this.qrUrl);
    //   console.log('qrCHL', qrCHL);

    //   const qrLink = `https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=${qrCHL}&choe=UTF-8`;
    //   console.log('qrLink', qrLink);

    //   return qrLink;
    // },
  },
  async created() {
    // this.isStaff = this.currentCollective.isStaff;
    console.log('this.member', this.member)
    if(this.member){
      this.loggedMember = this.member;
    }else {
      await this.fetchMember();
    }
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    async fetchMember() {
      const response = await this.$store.$service[Service.BackendClient].get(
        "/communityMembers",
        {
          params: {
            communitySlug: this.$store.getters.currentCollective.slug,
            username: this.member.username,
            isForProfile: true,
          },
        }
      );

      const member = response.data.data[0];
      console.log('member', member)

      // this.loggedMember = member;
    },
    handlePrintCode() {
      this.printing = true;
    },
    handleClipboardCopied() {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3500);
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
      this.isMedium =  window.innerWidth > 1100;
    },
    hexToDecimal(member) {
      return parseInt(member?.key, 16);
    },
  },
};
</script>

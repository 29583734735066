<template>
  <div 
    :class="menuType !== 'vertical' && $route.name !== 'my-role-details' ? '' : ''"
  >
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { startNotifications, sendMixpanelNotification } from '@/@core/utils/notifications/notifications';

export default {
  name: "MemberSettingContainer",
  data() {
    return {
      isUser: false,
    };
  },
  computed: {
    ...mapGetters(['loggedMember', 'currentCollective']),

    menuType() {
      return this.currentCollective?.menu?.layout;
    },
  },
  async created() {
    const memberKey = this.loggedMember?.key;
    const communitySlug = this.currentCollective.slug;
    await startNotifications({ memberKey, communitySlug });
    await sendMixpanelNotification('member-in-myaccount');
  },
};
</script>
